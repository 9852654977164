const lottie = require('lottie-web');

document.addEventListener("DOMContentLoaded", () => {

    document.querySelectorAll(".lottie-animation").forEach((el) => {
        lottie.loadAnimation({
            container: el,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: el.dataset.path
        });
    });

});
